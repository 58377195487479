<template>
<div>
  <router-view />
</div>
</template>

<script>
export default {
  mounted() {
    console.log("montó home subdominio", this.$route);
  },
  beforeDestroy() {
    this.$store.commit("setDomain", "");
    this.$store.commit("setStatusSubDomain", false);
  }
}
</script>

<style>

</style>